<template>
  <div class="pa-4 h-100-p w-100-p">
    <div class="title mb-4">
      <h1>Concord</h1>
    </div>
    <div class="w-100-p" v-if="folders.length > 0">
      <v-container class="pa-0 pl-4">
        <v-row>
          <v-form class="w-100-p" @submit.prevent>
            <div class="w-100-p d-flex align-center">
              <v-col cols="4" sm="3">
                <BaseSelect
                    :label="'Search By'"
                    :modelValue="searchItem"
                    :options="searchOptions"
                    @updateModel="updateSearchBy">
                </BaseSelect>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    label="Search"
                    append-icon="mdi-magnify"
                    v-model="searchValue"
                ></v-text-field>
              </v-col>
              <v-col cols="3" v-if="searchItem !== 'Word'">
                <v-text-field
                    :label="searchItem === 'PoS' ? 'Search for center word' : 'Search for center pos'"
                    append-icon="mdi-magnify"
                    v-model="centerSearchValue"
                    :disabled="!searchValue || searchValue === ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn type="submit" color="primary ml-3" @click="applySearch()" :disabled="getApiPending">
                  Search
                </v-btn>
              </v-col>
            </div>
          </v-form>
        </v-row>
      </v-container>
      <div class="concord-table pl-4">
        <WordConcord
            :center-and-contexts="centerAndContexts"
            :displayBy="searchItem"
            :center-search="centerSearchValue"
            :page="page"
            :per_page="per_page"
            :total-elements="totalElements"
            @paginationChange="getNewPage($event)"
        >
        </WordConcord>
      </div>
    </div>
    <div class="w-100-p" v-else>
      <h4 class="pa-4 no-folder-display">Please add folder to use concord</h4>
    </div>
    <InfoSnackbar :snackbar="showSnackbar" :text="snackbarText" @closeSnackBar="closeSnackBar()">
    </InfoSnackbar>
  </div>

</template>

<script>
import WordConcord from "../components/search/word-concord";
import BaseSelect from "../components/reuseable/count-by-select";
import InfoSnackbar from "../components/reuseable/info-snackbar";
import {mapGetters} from "vuex";

export default {
  components: {WordConcord, BaseSelect, InfoSnackbar},
  data: function () {
    return {
      searchValue: '',
      centerSearchValue: '',
      searchOptions: ['Word', 'Word w/ PoS', 'PoS'],
      searchItem: 'Word',
      folders: [],
      centerAndContexts: {},
      lastSearchItem: 'Word',
      lastSearch: '',
      lastCenterSearch: '',
      allCenterAndContexts: {},
      showSnackbar: false,
      snackbarText: '',
      page: 1,
      per_page: 10,
      totalElements: 0,
      options: {
        page: this.page,
        itemsPerPage: this.per_page,
      },
      query: null
    }
  },
  computed: {
    ...mapGetters([
      'getFolders',
      'getConcordSearch',
      'getConcordDisplayBySearch',
      'getApiPending',
    ])
  },
  created() {
    if (this.getConcordDisplayBySearch) {
      this.updateSearchBy(this.getKeywordFolderSearch);
    }

    if (this.getConcordSearch) {
      setTimeout(() => this.searchValue = this.getConcordSearch)
    }
  },
  methods: {
    updateSearchBy(value) {
      this.searchItem = value;
      this.searchValue = '';
      this.centerSearchValue = '';
    },
    applySearch() {
      if (this.searchValue === '') {
        return;
      }

      if (this.lastSearchItem === this.searchItem && this.lastSearch === this.searchValue
          && this.lastCenterSearch === this.centerSearchValue) {
        return;
      }

      this.$store.dispatch("setConcordSearch", this.searchValue);
      this.$store.dispatch("setConcordDisplayBySearch", this.searchItem);

      let query = {
        word: this.searchItem !== 'PoS',
        pos: this.searchItem === 'PoS',
        wtoken: this.searchItem !== 'PoS' ? this.searchValue.split(' ').join("_") : this.centerSearchValue.split(' ').join("_"),
        ptoken: this.searchItem === 'PoS' ? this.searchValue.split(' ').join("_") : this.centerSearchValue.split(' ').join("_"),
        page: 1,
        per_page: this.per_page
      };
      this.query = query;
      this.page = 1;

      this.lastSearchItem = this.searchItem;
      this.lastSearch = this.searchValue;
      this.lastCenterSearch = this.centerSearchValue;
      this.getContext(query, null);
    },
    getNewPage(pagination) {
      if(this.query && (pagination.page !== this.page || pagination.per_page !== this.per_page)) {
        this.getContext(this.query, pagination);
        this.page = pagination.page;
        this.per_page = pagination.per_page;
      }
    },
    async getContext(query, pagination) {
      if (pagination) {
        query = {
          ...query,
          page: pagination.page,
          per_page: pagination.per_page,
        }
      }
      this.$store.dispatch('setApiPending', true);
      try {
        let res = await this.$api.preprocess.contexts({query});
        this.$store.dispatch('setApiPending', false);

        if (res.status === 200 && res.data) {
          this.centerAndContexts = res.data;
          this.totalElements = res.data.count;
        } else {
          this.snackbarText = JSON.stringify(res.data["err"]);
          this.showSnackbar = true;
        }
      } catch (error) {
        this.$store.dispatch('setApiPending', false);
        this.snackbarText = JSON.stringify(error);
        this.showSnackbar = true;
      }

    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
  },
  watch: {
    getFolders: {
      immediate: true,
      handler(folders) {
        this.folders = Object.keys(folders);
      }
    }
  },
}
</script>

<style scoped>

</style>
