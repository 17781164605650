<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="800px"
        @click:outside="$emit('closeDialog')"
        @keydown.esc="$emit('closeDialog')"
    >
      <v-card>
        <v-card-title>{{ this.foldername }} / {{ this.filename }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 500px;">
          <div class="w-100-p pt-5 text-color-black" v-html="fileContent"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="w-100-p d-flex justify-end">
            <v-btn
                color="primary"
                text
                @click="$emit('closeDialog')"
            >
              Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ViewConcordDocumentDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    fileContent: {
      type: String,
    },
    filename: {
      type: String,
    },
    foldername: {
      type: String,
    }
  },
}
</script>
