<template>
  <v-select
      :items="items"
      v-model="selectedValue"
      :label="label"
      @change="updateSelectedValue($event)"
      single-line
  ></v-select>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    modelValue: {
      type: String,
      required: true,
      default: this?.selectedValue || '',
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: 'Display by'
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.selectedValue = newValue;
      },
    },
    options: {
      immediate: true,
      handler(newValue) {
        this.items = newValue;
        if (!this.items.includes(this.selectedValue)) {
          this.selectedValue = this.items[0];
          this.$emit("updateModel", this.selectedValue);
        }
      },
    }
  },
  data: function () {
    return {
      items: this.options,
      selectedValue: "All",
    };
  },
  methods: {
    updateSelectedValue(value) {
      this.$emit("updateModel", value);
    },
  },
};
</script>
