<template>
  <div class="pa-4 h-100-p w-100-p" v-resize="onResize">
    <div class="w-100-p">
      <v-data-table
          :headers="headers"
          :items="concordData"
          class="elevation-1 concord-table"
          fixed-header
          :height="windowSize.y - 304"
          @click:row="openDialogToViewFile($event)"
          :page="page"
          :items-per-page="per_page"
          :server-items-length="totalElements"
          :footer-props="{
            'items-per-page-options': [10, 20, 30]
          }"
          @pagination="onPaginationChange($event)"
      >
        <template slot="item.id" slot-scope="props">
          {{ props.index + 1 }}
        </template>
        <template slot="item.prepend" slot-scope="props">
          <div v-html="props.item.prepend"></div>
        </template>
        <template slot="item.word" slot-scope="props">
          <div v-html="props.item.word"></div>
        </template>
        <template slot="item.append" slot-scope="props">
          <div v-html="props.item.append"></div>
        </template>
      </v-data-table>
    </div>
    <ViewConcordDocumentDialog :dialog="showDialog" :fileContent="dialogData" :filename="filename"
                               :foldername="foldername"
                               @closeDialog="closeViewFileDialog"></ViewConcordDocumentDialog>
    <InfoSnackbar :snackbar="showSnackbar" :text="snackbarText" @closeSnackBar="closeSnackBar()">
    </InfoSnackbar>
  </div>
</template>
<script>
import ViewConcordDocumentDialog from "../reuseable/view-concord-document-dialog";
import InfoSnackbar from '../reuseable/info-snackbar';

export default {
  components: {ViewConcordDocumentDialog, InfoSnackbar},
  props: {
    centerAndContexts: {
      type: Object,
      require: true,
    },
    displayBy: {
      type: String,
      require: true,
    },
    page: {
      type: Number,
      require: true,
      default: 1
    },
    per_page: {
      type: Number,
      required: true
    },
    totalElements: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      concordData: [],
      windowSize: {
        x: 0,
        y: 0,
      },
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
          filterable: false
        },
        {
          text: '',
          align: 'end',
          value: 'prepend',
          sortable: false,
          filterable: false
        },
        {
          text: '',
          align: 'center',
          value: 'word',
          sortable: false,
          filterable: true
        },
        {
          text: '',
          align: 'start',
          value: 'append',
          sortable: false,
          filterable: false
        },
      ],
      showDialog: false,
      dialogData: null,
      filename: null,
      foldername: null,
      center: [],
      contexts: [],
      showSnackbar: false,
      snackbarText: '',
      pagingOptions: {
        page: 1,
        itemsPerPage: 100
      },
      totalItemLength: 0
    }
  },
  watch: {
    centerAndContexts: {
      handler(newVal) {
        this.concordData = [];

        if (newVal.center && newVal.contexts) {
          this.center = newVal.center;
          this.contexts = newVal.contexts;
        }

        this.concordData = this.center.map((centerWord, index) => {
          const selectedLine = this.contexts[index];
          const centerIndex = centerWord.index;
          const prependIndex = centerIndex - 10 > 0 ? centerIndex - 10 : 0;
          const appendIndex = centerIndex + 10 < selectedLine.length ? centerIndex + 10 : selectedLine.length;

          const prependArray = selectedLine.slice(prependIndex, centerIndex);
          const appendArray = selectedLine.slice(centerIndex + 1, appendIndex + 1);

          if (this.displayBy === 'Word') {
            const prependWords = prependArray.map(prepend => prepend.tok).join(' ');
            const appendWords = appendArray.map(append => append.tok).join(' ');
            return {
              prepend: prependWords,
              word: centerWord.tok,
              append: appendWords,
              filename: centerWord.filename,
              foldername: centerWord.foldername,
              highlightedWords: prependWords + ' ' + centerWord.tok + ' ' + appendWords,
              line: centerWord.line
            };
          } else {
            let prependWordsAndPoses = '';
            let appendWordsAndPoses = '';
            let prependHighlightedWords = '';
            let appendHighlightedWords = '';

            prependArray.forEach((prependItem, index) => {
              const html = prependItem.tok + `<span class="display-pos">/${prependItem.pos}</span>`;
              prependWordsAndPoses += index === 0 ? html : ' ' + html;
              prependHighlightedWords += index === 0 ? prependItem.tok : ' ' + prependItem.tok;
            });

            appendArray.forEach((appendItem, index) => {
              const html = appendItem.tok + `<span class="display-pos">/${appendItem.pos}</span>`;
              appendWordsAndPoses += index === 0 ? html : ' ' + html;
              appendHighlightedWords += index === 0 ? appendItem.tok : ' ' + appendItem.tok;
            });

            return {
              prepend: prependWordsAndPoses,
              word: centerWord.tok + `<span class="display-pos">/${centerWord.pos}</span>`,
              append: appendWordsAndPoses,
              filename: centerWord.filename,
              foldername: centerWord.foldername,
              highlightedWords: prependHighlightedWords + ' ' + centerWord.tok + ' ' + appendHighlightedWords,
              line: centerWord.line
            };
          }
        })
      }
    },
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
    onPaginationChange(paging) {
      this.$emit('paginationChange', {
        page: paging.page,
        per_page: paging.itemsPerPage
      })
    },
    async openDialogToViewFile(item) {

      const payload = {
        folder: item.foldername,
        file: item.filename
      };

      this.$store.dispatch('setApiPending', true);
      try {
        let res = await this.$api.preprocess.get_content(payload);
        this.$store.dispatch('setApiPending', false);

        if (res.status === 200 && res.data) {
          const sortedData = res.data.sort(function (a, b) {
            return a.line - b.line || a.index - b.index;
          });

          const fileContent = sortedData.map(word => {
            if (word.line === item.line) {
              return `<span class="highlighted-words">${word.tok}</span>`
            } else {
              return word.tok
            }
          }).join(' ');

          this.dialogData = fileContent;
          this.filename = item.filename;
          this.foldername = item.foldername;
          this.showDialog = true;
        } else {
          this.snackbarText = JSON.stringify(res.data["err"]);
          this.showSnackbar = true;
        }
      } catch (error) {
        this.$store.dispatch('setApiPending', false);
        this.snackbarText = JSON.stringify(error);
        this.showSnackbar = true;
      }
    },

    closeViewFileDialog() {
      this.showDialog = false;
    },
  },
}
</script>
<style>

.concord-table tr {
  cursor: pointer;
  white-space: nowrap;
}

.concord-table td.text-start, .concord-table td.text-end {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.concord-table td.text-start:first-of-type {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.concord-table td.text-center {
  padding-left: 8px !important;
  padding-right: 8px !important;
  color: red !important;
  font-weight: bold;
}

.highlighted-words {
  color: red;
  font-weight: bold;
}

</style>
